import logo from "../logo.svg";

export default () => {
    return (
        <img
            src={logo}
            alt="logo"
            className="App-logo"
            width="200"
            height="200"
        />
    );
}