import Curve3 from './curves/Curve3';
import { Dark } from '../App';
import React, {useState} from 'react';
import { Row, Container, Col, Button, Form, Alert } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { Formik } from 'formik';
import { object, string } from 'yup';
import Curve2 from "./curves/Curve2";
import Footer from "./Footer";
import login from "../api/auth/login";
import getGroup from "../api/auth/getGroup";

export default () => {
    const [ error, setError ] = useState('');
    const navigate = useNavigate();
    const localUsername = localStorage.getItem("username");

    return (
        <div>
            <Container fluid={true}>
                <Row>
                    <Col className="bg-dark">
                        <div className="me-5 ms-5 ps-5 pe-5 mt-5 text-danger">
                            <Formik
                                initialValues={{ username: (localUsername == null ? '' : localUsername), password: '' }}
                                validationSchema={object().shape({
                                    username: string().required('Username field is required.'),
                                })}
                                onSubmit={(values, { setSubmitting }) => {
                                    login({username: values.username, password: values.password})
                                        .then(resolve => {
                                            if (!resolve.success) {
                                                setError((resolve.error == null ? 'unknown error' : resolve.error));
                                            } else {
                                                // @ts-ignore
                                                localStorage.setItem("token", resolve.token);

                                                getGroup()
                                                    .then(resolve => {
                                                        if (!resolve.success) {
                                                            console.error(resolve.error);
                                                            setError((resolve.error == null ? 'unknown error' : resolve.error));
                                                            return;
                                                        }

                                                        if (resolve.name !== "unverified") {
                                                            navigate('/discuss');
                                                        } else {
                                                            setError('your account is not verified yet, ' +
                                                                'please try again later');
                                                        }
                                                    })
                                                    .catch(error => {
                                                        console.error(error);
                                                    });
                                            }
                                        })
                                        .catch(error => {
                                            console.error(error);
                                        })
                                    setTimeout(() => {
                                        setSubmitting(false);
                                    }, 400);
                                }}
                            >
                                {({
                                      values,
                                      errors,
                                      touched,
                                      handleChange,
                                      handleBlur,
                                      handleSubmit,
                                      isSubmitting,
                                  }) => (
                                    <Form onSubmit={handleSubmit}>
                                        {error !== '' ?
                                            <Alert className="mt-3" variant="danger">
                                                Error: {error}
                                            </Alert>
                                            :
                                            <div/>
                                        }
                                        <h2 className="text-white">Login</h2>
                                        <Form.Group className="mb-1 mt-4 pt-1">
                                            <Form.Label className="dark-text">
                                                Username
                                            </Form.Label>
                                            <Form.Control
                                                type="username"
                                                name="username"
                                                placeholder="Username..."
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.username}
                                            />
                                        </Form.Group>
                                        {errors.username && touched.username && errors.username}

                                        <Form.Group className="mt-4 mb-3">
                                            <Form.Label className="dark-text">
                                                Password
                                            </Form.Label>
                                            <Form.Control
                                                type="password"
                                                name="password"
                                                placeholder="Password..."
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.password}
                                            />
                                        </Form.Group>
                                        {errors.password && touched.password && errors.password}

                                        <Button type="submit" className="mb-3" disabled={isSubmitting}>
                                            Login
                                        </Button>
                                        <Form.Text>
                                            <br/>
                                            Forgot your details? Email us at xxxxxxx@xxxxxx.com
                                        </Form.Text>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </Col>
                    <Col style={{ paddingLeft: 0, paddingRight: 0 }} className="bg-white">
                        <div className="text-end me-5 pe-3 mt-5 ps-5 ms-5">
                            <h2>Need help?</h2>
                            <p className="d-inline">
                                Use your randomly generated username (user123456 for example) along with your password
                                to login to your account. Usernames are unchangeable and generated only once,
                                don't lose it.
                            </p>
                            <p className="text-secondary" style={{ fontSize: 10 }}>
                                Oh, and we're not dumb, passwords are stored securely.
                            </p>
                        </div>
                        <Curve3 fill={Dark}/>
                    </Col>
                </Row>
            </Container>
            <div className="dark-curve">
                <Curve2 fill={Dark}/>
                <Footer/>
            </div>
        </div>
    );
}