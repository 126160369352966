import http from '../http';

export interface LatestResponse {
    success: boolean;
    error: string | null;
    messages: string[] | null;
}

export default (): Promise<LatestResponse> => {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem("token");
        if (token == null) {
            return;
        }

        http.get('/chat/messages/latest', {
            headers: {
                'Authorization': `${token as string}`,
            },
        })
            .then(response => {
                return resolve({
                    success: response.data.success,
                    error: response.data.error,
                    messages: response.data.messages,
                });
            })
            .catch(reject);
    })
}