import { ComponentProps } from "react";

export default (props: ComponentProps<any>) => {
    return (
        <svg
            viewBox="0 0 2880 150"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M 0 0 c 1440 275 2880 0 2880 0 V 0"
                fill={props.fill}
            />
        </svg>
    );
}
