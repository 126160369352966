import http from '../http';

export interface LoginResponse {
    success: boolean;
    error: string | null;
    token: string | null;
}

export interface LoginData {
    username: string;
    password: string;
}

export default ({ username, password }: LoginData): Promise<LoginResponse> => {
    return new Promise((resolve, reject) => {
        http.post('/auth/login', {
            username: username,
            password: password,
        })
            .then(response => {
                return resolve({
                   success: response.data.success,
                   error: response.data.error || null,
                   token: response.data.token || null,
                });
            })
            .catch(reject);
    })
}