import {Col, Row, Container, Form, Button} from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Formik} from 'formik';
import newMessage from "../api/chat/newMessage";
import latest from "../api/chat/latest";
import MessageBox from "./MessageBox";

export default () => {

    return (
        <div>
            <Container fluid={true} className="">
                <Row>
                    <Col className="bg-dark full-height text-light">
                        <MessageBox/>
                        <Formik
                            initialValues={{ message: '' }}
                            onSubmit={(values, { setSubmitting }) => {
                                if (values.message.trim() != '') {
                                    newMessage({ message: values.message })
                                        .then(resolve => {

                                        })
                                        .catch(error => {
                                            console.error(error);
                                        })
                                }

                                setTimeout(() => {
                                    setSubmitting(false);
                                }, 200);
                            }}
                        >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleBlur,
                                  handleSubmit,
                                  isSubmitting,
                              }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Control
                                                    className="ms-2 input-dark text-white"
                                                    type="message"
                                                    name="message"
                                                    placeholder="..."
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.message}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col className="col-1">
                                            <Button type="submit" variant="secondary" disabled={isSubmitting}>
                                                Send
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </Col>
                    <Col className="col-2 bg-darker text-center full-height text-light">
                        <div className="mt-4">
                            <h4>Channels</h4>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className="pt-1 dark-curve Footer-small text-center">
                <p className="darker-text">Ball Pit Messaging</p>
            </div>
        </div>
    );
}
