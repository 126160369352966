import { ComponentProps } from "react";

export default (props: ComponentProps<any>) => {
    return (
        <svg
            viewBox="0 0 2880 1300"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M 0 0 c 0 400 0 800 2880 800 V 1300 H 0 Z"
                fill={props.fill}
            />
        </svg>
    );
}
