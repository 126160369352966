import * as React from 'react';
import logo from '../logo.svg';
import { Container, Navbar, Nav, Row, Button } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.css';

export default () => {
    return(
        <div className="Navbar-height">
            <Navbar bg="light" expand="lg">
                <Container>
                    <Navbar.Brand href="/">
                        <img
                        src={logo}
                        width="30"
                        height="30"
                        className="d-inline-block align-top me-1"
                        />{' '}
                        Ball Pit
                    </Navbar.Brand>
                        <Nav className="me-auto">
                            <Nav.Link className="nav-link" href="/discuss">
                                Discuss
                            </Nav.Link>
                        </Nav>
                    <Row>
                        <Nav className="flex-row">
                            <Button href="/login" className="mx-1" variant="outline-dark">
                                Login
                            </Button>
                            <Button href="/join" variant="outline-dark">
                                Join
                            </Button>
                        </Nav>
                    </Row>
                </Container>
            </Navbar>
        </div>
    );
}