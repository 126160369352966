import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavBar from './components/NavBar';
import Home from './components/Home';
import Discuss from "./components/Discuss";
import Login from './components/Login';
import Join from './components/Join';

export const Dark = "#212529";

function App() {
  return (
      <div>
          <NavBar/>
          <Router>
              <Routes>
                  <Route path="/discuss" element={<Discuss/>}/>
                  <Route path="/" element={<Home/>}/>
                  <Route path="/login" element={<Login/>}/>
                  <Route path="/join" element={<Join/>}/>
              </Routes>
          </Router>
      </div>
  );
}

export default App;
