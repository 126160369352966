import {Container, Row, Col, Form, Button, Alert} from 'react-bootstrap';
import { Formik } from 'formik';
import {object, mixed, string, ref} from 'yup';
import React, {ChangeEvent, useState} from "react";
import Curve2 from "./curves/Curve2";
import Footer from "./Footer";
import register from "../api/auth/register";

export default () => {
    const [ disabled, setDisabled ] = useState(false);
    const [ username, setUsername ] = useState('');

    return (
      <div>
          <Container>
              <Row className="mb-5 mt-4 justify-content-between">
                  {disabled
                      ? <Alert className="mt-4" variant="success">
                          Thank you for creating an account, your username is {username}.
                          <b> Do not forget your username!</b> For anonymity, it is the only way to
                          identify you.
                      </Alert>
                      :
                      <div className="mb-5"/>
                  }
                  <Col className="col-5 mt-2">
                      <h2 className="mb-3">Need help signing up?</h2>
                      <p>
                          A photo of your student ID is all we need to get you signed up. Once you've sent in a request,
                          you'll need to wait for a few hours to be manually verified. (We want to make sure our users
                          are legit.) If you lost your school ID, you can send another form of identification with your
                          name and picture. This is discouraged though, because there is generally more personal info on
                          the other forms of identification compared to a student ID. ID is purely used for signing up,
                          after that, all files are discarded.
                      </p>
                  </Col>
                  <Col className="col-5 mt-2">
                      <div className="text-danger">
                          <Formik
                              initialValues={{ file: null, password: '', passwordConfirmation: '' }}
                              validationSchema={object().shape({
                                  password: string().required('Password is required.')
                                      .min(7, 'Your password must be at least 7 characters in length.'),
                                  passwordConfirmation: string()
                                      .required('Your new password does not match.')
                                      .oneOf([ ref('password'), null ], 'Your new password does not match.'),
                                file: mixed().required('Image is required')
                              })}
                              onSubmit={(values, { setSubmitting }) => {
                                // @ts-ignore
                                register({file: values.file, password: values.password})
                                      .then(resolve => {
                                          setDisabled(true);
                                          setUsername(resolve.username);

                                          localStorage.setItem("username", resolve.username);
                                      })
                                      .catch(error => {
                                          console.error(error);
                                      });
                                  setTimeout(() => {
                                      setSubmitting(false)
                                  }, 400)
                              }}
                          >
                              {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleBlur,
                                  handleSubmit,
                                  isSubmitting,
                                  setFieldValue,
                              }) => (
                                  <Form onSubmit={handleSubmit}>
                                      <div>
                                          <h2 className="text-black text-end">Sign up</h2>
                                          <Form.Group className="text-black mb-1 mt-4">
                                              <Form.Label>
                                                  Student ID
                                              </Form.Label>
                                              <Form.Control
                                                  disabled={disabled}
                                                  id="file"
                                                  type="file"
                                                  name="file"
                                                  accept="image/*"
                                                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                      if (e.target && e.target.files) {
                                                          setFieldValue("file", e.target.files[0]);
                                                      }
                                                  }}
                                                  onBlur={handleBlur}
                                              />
                                          </Form.Group>
                                          {errors.file && touched.file && errors.file}

                                          <Form.Group className="text-black mb-1 mt-3">
                                              <Form.Label>
                                                  Password
                                              </Form.Label>
                                              <Form.Control
                                                  disabled={disabled}
                                                  id="password"
                                                  type="password"
                                                  name="password"
                                                  placeholder="Password..."
                                                  onChange={handleChange}
                                                  onBlur={handleBlur}
                                                  value={values.password}
                                              />
                                          </Form.Group>
                                          {errors.password && touched.password && errors.password}

                                          <Form.Group className="text-black mt-3">
                                              <Form.Label>
                                                  Confirm Password
                                              </Form.Label>
                                              <Form.Control
                                                  disabled={disabled}
                                                  id="passwordConfirmation"
                                                  type="password"
                                                  name="passwordConfirmation"
                                                  placeholder="Confirm password..."
                                                  onChange={handleChange}
                                                  onBlur={handleBlur}
                                                  value={values.passwordConfirmation}
                                              />
                                          </Form.Group>
                                          {errors.passwordConfirmation && touched.passwordConfirmation && errors.passwordConfirmation}
                                          <br/>
                                          <Button type="submit" className="mb-3 mt-1" disabled={isSubmitting}>
                                              Submit
                                          </Button>

                                          <Form.Text>
                                              <br/>
                                              Once submitted, it may take a few hours for us to verify the information.
                                              You may also check the status of your submission by attempting to login with
                                              the username provided on submission, it will let you know if your application
                                              is still pending.
                                          </Form.Text>
                                      </div>
                                  </Form>
                              )}
                          </Formik>
                      </div>
                  </Col>
              </Row>
          </Container>
          <div className="dark-curve">
              <Curve2 fill="#FFFFFF"/>
              <Footer/>
          </div>
      </div>
    );
}
