import http from '../http';

export interface MessageResponse {
    success: boolean;
    error: string | null;
}

export interface MessageData {
    message: string;
}

export default ({ message }: MessageData): Promise<MessageResponse> => {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem("token");
        if (token == null) {
            return;
        }

        http.post('/chat/messages', {
            message: message
        } ,{
            headers: {
                'Authorization': `${token as string}`,
            },
        })
            .then(response => {
                return resolve({
                    success: response.data.success,
                    error: (response.data.success ? null : response.data.error),
                });
            })
            .catch(reject);
    })
}