import {Col, Container, Row} from "react-bootstrap";
import Logo from "./Logo";
import Curve1 from "./curves/Curve1";
import sf from "../saintefamille.png";
import { Dark } from '../App';
import Curve2 from "./curves/Curve2";
import Footer from "./Footer";

export default () => {
    return (
        <>
            <Container className="pt-4 mt-5">
                <Row className="justify-content-between">
                    <Col className="col-5 text-center mt-4">
                        <Logo/>
                    </Col>
                    <Col className="col-5 text-end">
                        <h2>Welcome to Ball Pit</h2>
                        <p>
                            Ball Pit is an anonymous online chatroom for high-school students, made by students.
                            Whatever you plan on doing here, we can ensure your identity and activities will stay
                            completely anonymous. You can enjoy chatting, sharing homework, and creating secret
                            underground school markets in confidence that your actions will remain confidential and
                            inaccessible to outsiders. Whether you found us online, heard about us in school, or were
                            even given a private code from a member of your school, this private channel is for whatever
                            the hell you want to talk about. What goes in, doesn't come out.
                        </p>
                    </Col>
                </Row>
            </Container>

            <Curve1 fill={Dark}/>

            <div className="bg-dark">
                <Container className="pt-5 pb-5 text-white">
                    <Row className="justify-content-between mb-3">
                        <Col className="col-4">
                            <h2>We All Stay Safe</h2>
                            <p className="dark-text">
                                Ball Pit has 3 layers of security to ensure only the right people can get in. First off,
                                a photo of your school ID is needed to make sure you're a student. Once manually
                                verified, you won't need to be verified again. (Just don't forget your account number!)
                                Secondly, everyone in the Ball Pit goes by their user number, not their name. (The whole
                                point of this website is to keep your identity hidden.) Finally, all registered schools
                                have been IP and location blocked, so users can only access the chatroom outside of
                                school. This prevents unauthorized users from accessing the site. You also shouldn't be
                                on your phone in class.
                            </p>
                        </Col>
                        <Col className="col-5 mt-3 pt-5 text-center">
                            <img
                                src={sf}
                                alt="logo"
                                className="rounded-corners"
                                width="400"
                                height="400"
                            />
                        </Col>
                    </Row>
                </Container>
                <div className="dark-curve">
                    <Curve2 fill={Dark}/>
                    <Footer/>
                </div>
            </div>
        </>
    );
}