import http from '../http';

export interface RegisterResponse {
    username: string;
}

export interface RegisterData {
    file: File;
    password: string;
}

export default ({ file, password }: RegisterData): Promise<RegisterResponse>  => {
    return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("file", file as Blob)
        formData.append("password", password)
        http.post('/auth/register', formData, {headers: {
                'Content-Type': 'multipart/form-data'
            }})
            .then(response => {
                return resolve({
                    username: response.data.username,
                });
            })
            .catch(reject);
    });
};
