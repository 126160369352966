import http from '../http';

export interface GroupResponse {
    success: boolean;
    error: string | null;
    name: string | null;
    display_name: string | null;
}

export default (): Promise<GroupResponse> => {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem("token");
        if (token == null) {
            return;
        }

        http.get('/auth/get_group', {
            headers: {
                'Authorization': `${token as string}`,
            },
        })
            .then(response => {
                return resolve({
                    success: response.data.success,
                    error: (response.data.success ? null : response.data.error),
                    name: response.data.name,
                    display_name: response.data.name,
                });
            })
            .catch(reject);
    })
}