import { Row, Col } from "react-bootstrap";

export interface Message {
    user: string;
    message: string;
    timestamp: string;
}

const timeToLocalTime = (time: string): string => {
    const hour = time[0] === '0' && time[1] === '0' ? "12" : time[0] + time[1];
    time = time.slice(2, 5);

    console.log(+hour);
    return +hour > 12 ? (+hour - 12).toString() + time + " PM" : hour + time + " AM";
}

export default ({ user, message, timestamp }: Message) => {
    const time = timeToLocalTime(timestamp.slice(11));

    return (
        <Row className="mt-2">
            <Col className="col-1 ms-2 text-secondary" style={{ fontSize: `13px` }}>
                <p>{time}</p>
            </Col>
            <Col>
                <p style={{ display: `inline`}} className="me-2">{user}</p>
                <p style={{ display: `inline`}}>{message}</p>
            </Col>
        </Row>
    );
}