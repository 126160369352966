import { Row, Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import latest from "../api/chat/latest";
import MessageRow from "./MessageRow";

export default () => {
    const [ messages, setMessages ] = useState([])

    useEffect(() => {
        const timeout = setTimeout(() => {
            latest()
                .then(resolve => {
                    if (resolve.success) {
                        setMessages(resolve.messages as []);
                        console.log(resolve.messages as []);
                    }
                })
                .catch(error => {
                    console.error(error);
                })
        }, 125);
        return () => clearTimeout(timeout);
    })

    return (
        <Row className="message-box" style={{
            maxHeight: 'calc(80vh)',
            overflowY: 'auto'
        }}
        >
            {
                messages?.map((message: any, index) => (
                <MessageRow
                    key={index}
                    user={message.user}
                    message={message.message}
                    timestamp={message.timestamp}
                />
            ))}
        </Row>
    );
}